.program_div {
  padding-top: 100px;
  padding-bottom: 100px;

  background-image: url("../../Resource/slider_image_10.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
}

.program_title {
  font-size: 50px;
  margin-bottom: 50px;
}
.s-card-1 {
  position: relative;
  height: 400px;
  width: auto;
}
.s-card-1 img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
.s-card-1-div {
  position: absolute;
  bottom: 0px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px 10px 0px 0px;
}
.card-div {
  border-radius: 10px;
  /* background-color: aquamarine; */
  overflow: hidden;
  /* box-shadow: 5px solid grey; */
  box-shadow: 10px 10px #c6dbf1;
}

.singleLineImageContainer {
  display: flex;
  width: 100%;
  /* background: #899; */
  overflow: auto;
}
