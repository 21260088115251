* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.c-img {
  /* height: 300px; */
  /* background-color: blue; */
}

.slider_screen {
  background-color: #dce8f5;
}
.research_screen {
  background-color: white;
}

.p-home-text {
  text-align: justify;
  text-justify: inter-word;
}
