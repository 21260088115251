.bg-cola-body {
  background-color: #dce8f5;
}

.h3-cola {
  text-align: center;
  color: #1f5156;
  /* font-family: cursive; */
  font-size: 50px;
  padding-bottom: 30px;
  font-weight: bold;
}
.div-cola-contain {
  padding-top: 50px;
  padding-bottom: 200px;
}

.donner-img {
  border-radius: 15px;
}

.donner-img:hover {
  box-shadow: 5px 5px 5px 5px #ababab;
  border-radius: 15px;
}
