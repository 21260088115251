.dash-collaborate-card {
  position: relative;
}

.dash-collaborate-card:hover {
  box-shadow: 5px 5px 5px 5px #ababab;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f3f5f7;
}

.dash-collaborate-card p {
  /* font-family: cursive; */
  font-size: 18px;
  font-weight: bold;
  padding-inline: 20px;
  /* text-align: justify; */
  /* text-justify: inter-word; */
}
.dash-collaborate-card:hover .delete-icon {
  visibility: visible;
}
