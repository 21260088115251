/* .image_work{
    height: 400px;
    width: 400px;
} */
/* .div-work-img{
    max-width: 100%;
    max-height: inherit;
} */

.pic_main_div {
  width: 100%;
  background-color: #dce8f5;
}
.loading-bg {
  background-color: #dce8f5;
}
