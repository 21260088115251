/* 75402b */
/* 4d4939 */
/* 803d28 */
/* #174945   rgba(23, 73, 69, 1) */
/* 1f5156   green-light */

.navbar {
  /* background-color: rgba(11, 123, 240, 1) ; */
  /* height: 80px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 1rem; */
  /* position: sticky; */
  /* top: 0; */
  margin: 0;
  padding: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
  /* width: 100%; */
  /* height: 80px; */
  /* max-width: 1500px; */
}

/* .nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  display: flex;
} */

.nav-img-logo {
  /* width: 7%; */
  /* height: 40px; */
  width: 40px;
}
.nav-img-logo-main {
  /* width: 8%; */
  width: 80px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  /* margin-right: 2rem; */
  /* line-height: 100px; */
  margin: 0px;
  padding: 0px;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 5px;
  /* margin-right: 1rem; */
  padding-top: 10px;
  /* background-color: green; */
}

.nav-item:after {
  /* content: "";
  display: block; */
  /* height: 3px; */
  /* width: 0; */
  /* color: white;
  background: transparent; */
  /* transition: width 0.7s ease, background-color 0.5s ease; */
}

.nav-item:hover {
  background-color: rgba(23, 73, 69, 0.3);
  border-radius: 10px;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-icon {
  display: none;
}
.nav-drawer-item {
  display: none;
}

.sub-dropdown-content {
  display: none;
  position: absolute;
  background: rgba(198, 219, 241, 0.5);

  margin-left: 7rem;
  text-align: start;
}
.sub-dropdown-content a {
  width: 150px;
  text-align: center;
}

.sub-item:hover .sub-dropdown-content {
  display: block;
}

/* //==================== */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(198, 219, 241, 0.5);
  color: white;
  max-width: 500px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 2rem;
  text-align: start;
}
.dropdown-content a {
  background-color: rgba(198, 219, 241, 0.5);
  color: #174945;
  padding: 12px 16px;
  text-decoration: none;
  width: 250px;
  display: block;
  border-bottom: 3px solid rgba(198, 219, 241, 0.7);
}
.nav-item:hover .dropdown-content {
  display: block;
}
.nav-item:hover .dropbtn {
  /* background-color: red; */
}

.dropdown-content a:hover {
  background-color: #8ba0a9;
  color: #174945;

  transition: width 0.5s ease, background-color 0.7s ease;
}

.p-vrc {
  font-size: 1rem;
  font-weight: 500;
  color: #174945;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border: 3px solid transparent;
}
/* .p-vrc:hover{
  background-color: #174945;
  font-size: 1rem;
  font-weight: 500;
  color: #174945;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border: 3px solid transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
  } */
/* .p-vrc:after {
    font-weight: bold;
    color: white;
    background: #ffdd40;
    border-bottom: 3px solid #ffdd40;
    transition: width 0.7s ease, background-color 0.5s ease;
  } */
.navbar-logo {
  display: block;
}

.nav-logo-a {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 60px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: rgba(198, 219, 241, 0.7);
    /* background: black; */
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    transition: width 0.7s ease, background-color 0.5s ease;
    /* height: 100%; */
  }

  /* .nav-item .active {
      color: #ffdd40;
      border: none;
    } */
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
    text-align: start;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 10%);
    font-size: 2rem;
    cursor: pointer;
    color: #174945;
  }

  .nav-item .active .dropdown-content {
    display: block;
  }
  .nav-logo-a {
    color: #174945;
    text-decoration: none;
    display: block;
    font-size: 25px;
    font-weight: bold;
    /* background-color: aqua; */
  }

  .nav-drawer-item {
    display: block;
  }

  .navbar-logo {
    display: none;
  }
}
