.p-home-text {
  font-size: 22px;
  letter-spacing: 0.1em;
  text-shadow: horizontal -shadow vertical-shadow blur-radius color;
}
/* .intro_body{
    position: relative;
} */

.intro_background_img {
  background-image: url("../../Resource/green_pit_viper.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
}
.introduction-div {
  padding-top: 100px;
}

@media screen and (max-width: 960px) {
  .introduction-div {
    padding-top: 100px;
    padding-bottom: 100px;
    /* background-color: aqua; */
  }
}

@media screen and (min-width: 960px) {
  .introduction-div {
    /* margin-top: 100px; */
  }
  .intro_h1 {
    margin-top: 100px;
  }
  .p-home-text {
    margin-bottom: 100px;
  }

  .slider_screen {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
