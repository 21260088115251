.bg-object-div {
  background-color: #dce8f5;
}

.bg-object-card {
  padding: 3%;
  border-radius: 10px;
  box-shadow: 10px 10px 10px 10px grey;
}

.bg-object-h3 {
  color: #1f5156;
  text-align: center;
  /* box-shadow: 5px 5px 5px 5px grey; */
  /* font-family: cursive; */
  font-size: 50px;
  padding-bottom: 30px;
  font-weight: bold;
}

.bg-objective-p {
  font-size: 22px;
  text-align: start;
  /* font-family: monospace; */
  text-align: justify;
  text-justify: inter-word;
}

@media screen and (max-width: 960px) {
  .bg-objective-p {
    font-size: 14px;
    text-align: start;
    /* font-family: monospace; */
    text-align: justify;
    text-justify: inter-word;
  }
}
