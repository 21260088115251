ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.li-item {
  background-color: aliceblue;
  color: black;
  padding: 10px;
  margin: 10px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 18px;
}

.li-item:hover {
  background-color: rgb(180, 213, 243);
  color: black;
  padding: 10px;
  margin: 10px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 18px;
}

.li-item-main {
  color: green;
  padding: 10px;
  margin: 10px;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 22px;
}
