.a_pic_link {
  text-decoration: none;
}
.a_pic_link:hover {
  text-decoration: underline;
}
.pic_title {
  color: black;
  font-weight: normal;
}
.pic_title:hover {
  color: black;
  font-weight: bold;
}

.category-title {
  color: #195c42;
  width: auto;
  padding-top: 5px;
  padding-bottom: 5px;

  font-size: 30px;
  font-weight: bold;
  border-bottom: 2px solid #195c42;
}
.category-div {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

@media screen and (min-width: 960px) {
  .image-container {
    /* width: 310px; */
    height: 280px;
    overflow: hidden;
  }
  .image-container img {
    /* width: 95%; */
    height: 100%;
    object-fit: cover;
  }
}

.image-contain {
  border-radius: 10px;
  object-fit: cover;
}

.imagelayout-main-bg {
  border: 5px solid rgba(198, 219, 241, 1);
  background: rgba(11, 123, 240, 0.3);
  position: relative;
  border-radius: 10px;
  margin-bottom: 10px;
}

.imagelayout-main-bg:hover {
  background: rgba(11, 123, 240, 0.7);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.image-container:hover {
  border-radius: 10px;
}
