.dash-team-member-card {
  position: relative;
}

.dash-team-member-card:hover {
  box-shadow: 5px 5px 5px 5px #ababab;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f3f5f7;
}

.dash-team-member-card p {
  /* font-family: cursive; */
  font-size: 18px;
  font-weight: bold;
  padding-inline: 20px;
  /* text-align: justify; */
  /* text-justify: inter-word; */
}
.dash-team-member-card:hover .delete-icon {
  visibility: visible;
}

.select-image {
  width: 100%;
  font-size: 16px;
  padding: 16px;
}
.dropdown-btn {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}
