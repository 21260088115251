.img-title {
  color: black;
  font-size: 18px;
}

.img-title:hover {
  color: black;
  font-size: 18px;
  font-weight: bold;
}
