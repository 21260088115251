.googlemaps_div {
  background-image: url("../../Resource/vrc_location_image.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
}

.maps-divs {
  width: 100%;
  height: 600px;
  border-radius: 10px;
}
