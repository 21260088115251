@media screen and (max-width: 960px) {
  .div-scroll {
    /* position: fixed; */
    width: 100%;
    left: 95%;
    bottom: 60px;
    height: 20px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #174945;
  }
}
@media screen and (min-width: 960px) {
  .div-scroll {
    position: fixed;
    width: 100%;
    left: 95%;
    bottom: 60px;
    height: 20px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #174945;
  }
}
