.modal-content-loading{
    width: 400px;
    height: 200px;
}

.loading-spinner-popup {
    width: 100px;
    height: 100px;
    border: 20px solid #f3f3f3; 
    border-top: 20px solid #383636; 
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    margin-top: 40px;
  }

  .title-input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  
.close-popup-btn {
  font-size: 15px;
  /* color: red; */
}
.close-popup-btn:hover {
  font-size: 30px;
  color: red;
}