.main-going-research {
  background-color: #dce8f5;
}

.bg-go-res-card {
  padding: 3%;
  border-radius: 10px;
  box-shadow: 10px 10px 10px 10px grey;
}

.go-res-h3 {
  color: #1f5156;

  font-size: 50px;
  padding-bottom: 30px;
  font-weight: bold;
}

.go-res-p {
  font-size: 22px;
  text-align: left;
  text-align: justify;
  text-justify: inter-word;
}
