.footer-div {
  display: block;
  /* height: 300px; */
  /* width: 100%;*/

  background-color: rgba(23, 73, 69, 0.8);
}

.order-list {
  list-style-type: none;
}

.footer-nav {
  display: block;
}

.icon-style {
  margin-left: 10px;
}

@media screen and (max-width: 960px) {
  .footer-nav {
    display: none;
  }
}

.logo-size {
  height: 30px;
  width: 30px;
}
