.insert-main-div {
  margin-top: 50px;
  margin-bottom: 50px;
}

.insert-text {
  color: green;
  font-size: 30px;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.form-div {
  padding: 50px;
  box-shadow: 5px 10px 5px 10px #888888;
  border-radius: 10px;
  border: 2px solid;
}

.label-text {
  color: black;
  font-size: 20px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}

.item-show {
  font-size: 18px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  color: white;
  background-color: #078823;
}

.item-link {
  color: white;
}

.item-del:hover {
  background-color: red;
}

.item-edit:hover {
  background-color: orange;
}

.item-read:hover {
  background-color: greenyellow;
}
