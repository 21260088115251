.bg-div {
  background-color: #dce8f5;
}

.bg-card {
  border-radius: 10px;
  box-shadow: 10px 10px 10px 10px grey;
}

.bg-rescue-h3 {
  color: #1f5156;
  /* box-shadow: 5px 5px 5px 5px grey; */
  font-size: 50px;
  padding-bottom: 30px;
  font-weight: bold;
}

.p-contact {
  font-size: 22px;
}
.b-rescue-vrc {
  font-size: 30px;
  font-family: monospace;
  font-weight: bold;
}

@media screen and (max-width: 960px) {
  .bg-rescue-h3 {
    color: #1f5156;
    /* box-shadow: 5px 5px 5px 5px grey; */
    font-size: 35px;
    padding-bottom: 30px;
    font-weight: bold;
  }
  .b-rescue-vrc {
    font-size: 20px;
    font-family: monospace;
    font-weight: bold;
  }
}
