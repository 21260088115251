.bg-div {
  background-color: #dce8f5;
}

.bg-contact-card {
  padding: 3%;
  border-radius: 10px;
  box-shadow: 10px 10px 10px 10px grey;
}

.bg-contact-h3 {
  color: #1f5156;
  /* box-shadow: 5px 5px 5px 5px grey; */
  font-size: 40px;
  padding-bottom: 30px;
  font-weight: bold;
}

.p-contact-us {
  font-size: 22px;
}
.b-contact-vrc {
  font-size: 30px;
  font-family: monospace;
  font-weight: bold;
}

@media screen and (max-width: 960px) {
  /* .b-contact-vrc{
        font-size: 30px;
        font-family: monospace;
        font-weight: bold;
    } */

  .p-contact-us {
    font-size: 20px;
  }
}
