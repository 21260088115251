.color {
  color: #3aafa9; /*  // rgba(58, 175, 169, 1) */
  color-adjust: #174945;
  color-scheme: #c6dbf1; /*rgba(198, 219, 241, 1)*/
  color-scheme2: #a35942;
  border-color: #1f5156;
  accent-color: #f5b921;
  blue-color: #0619b6;
  light-blue-color: #4b5bdd;

  gremolota: #507c6b;
  gremolota-light: #72b7a0;
}

.main-div {
  width: 100%;
  background-color: rgba(198, 219, 241, 0.4);
}

.nav-img-logo-app {
  width: 90px;
}

.vrc-name {
  color: #174945;
}

.web-name {
  color: #174945;
  text-decoration: none;
}
.web-name:hover {
  color: #174945;
  text-decoration: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  width: 100%;
  /* height: 500px; */
}

.fixed-logo {
  display: none;
}

.hotline_fixed {
  display: none;
  /* position:fixed; */
  bottom: 0;
  right: 0;
  /* padding-right: 20px; */
  justify-content: end;
  text-align: end;
  /* padding-bottom: 50px; */
}
.hotline-img {
  width: 90%;
}

@media screen and (max-width: 960px) {
  .fixed-logo {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 20px;
    justify-content: center;
    text-align: center;
    padding-bottom: 50px;
  }

  .arrow-up {
    position: fixed;
    width: 100%;
    left: 150px;
    bottom: 60px;
    height: 20px;

    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #174945;
  }
}

@media screen and (min-width: 1400px) {
  .hotline_fixed {
    display: block;
    /* position:fixed; */
    bottom: 0;
    right: 0;
    /* padding-right: 20px; */
    justify-content: end;
    text-align: end;
    /* padding-bottom: 50px; */
  }
}

/* //=======  Loader */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 100px;
  height: 100px;
  border: 20px solid #f3f3f3; /* Light grey */
  border-top: 20px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

  margin-top: 300px;
  margin-bottom: 300px;
}


.fixed-hotline-div{
  position: fixed;
  width: 50px;
  right: 0;
  top: 20%;
  /* background-color: white; */
  padding: 1%;
  -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

