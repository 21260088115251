.research-body {
  /* width: 100%;
    background-color: #dce8f5; */
}

/* .count-design{
    background: red;
    padding: 10px;

    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
} */

.research-item {
  font-size: 18px;
  font-weight: bold;
}

.research-item:hover {
  box-shadow: 5px 5px 5px 5px #ababab;
  border-radius: 5px;
  /* text-decoration: underline; */
  background: white;
}

.item-text {
  text-decoration: none;
  color: black;
}
.item-text:hover {
  text-decoration: underline;
  cursor: pointer;
  color: black;
}
