.carousel_video_bg {
  background-image: url("../../Resource/vrc_loop_gif.gif");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 960px) {
  .img-class {
    height: 300px;
    width: 100%;
  }
  .back {
    display: none;
  }
}
@media screen and (min-width: 960px) {
  .img-class {
    height: 400px;
    width: 600px;
    object-fit: cover;
    border-radius: 10px;
  }
  /* .carousel-div{
        height: 500px;
        width: 100%;
        background-color: #dce8f5;
        background-size: cover;
            
    
    } */
  .container-back-front {
    position: relative;
    width: 100%;
    height: 500px;
  }

  /* Back div */
  .back {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    z-index: 1;
  }

  /* Front div */
  .front {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 400px;
    z-index: 2;
    transform: translate(-50%, -50%);
    /* box-shadow: 0 10px 20px rgba(255, 255, 255, 0.7); */
    box-shadow: 0 10px 20px rgba(255, 255, 255, 1);
  }
}

.gif_img {
  width: 100%;
}

.front:before,
.front:after {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  background: linear-gradient(
    45deg,
    #faf5f8,
    #d3d3dd,
    #00ff00,
    #ffff00,
    #faf5f8,
    #faf5f8,
    #d3d3dd,
    #00ff00,
    #ffff00,
    #faf5f8
  );
  background-size: 400%;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -1;
  animation: steam 20s linear infinite;
  border-radius: 10px;
}
