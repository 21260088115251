.dash-video {
  position: relative;
}
.dash-video:hover {
  box-shadow: 5px 5px 5px 5px #ababab;
}

.dash-video:hover .delete-icon {
  visibility: visible;
}
