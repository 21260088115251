.main-div-logo {
  background-color: #c6dbf1;
}

.div-navbar-fas {
  display: none;
}

@media screen and (max-width: 960px) {
  .main-div-logo {
    display: none;
  }

  .div-navbar-fas {
    display: block;
  }
}
