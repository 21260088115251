.bg-cola-body {
  background-color: #dce8f5;
}
.div-cola-contain {
  /* background-color: white; */
}
.h3-cola {
  text-align: center;
  color: #1f5156;
  /* font-family: cursive; */
  font-size: 50px;
  padding-bottom: 30px;
  font-weight: bold;
}

.med-img {
  /* border-radius: 300px; */
  /* box-shadow: 5px 5px 5px 5px #ababab; */
}
.collaborate-card:hover {
  box-shadow: 5px 5px 5px 5px #ababab;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f3f5f7;
}

.collaborate-card p {
  /* font-family: cursive; */
  font-size: 18px;
  font-weight: bold;
  padding-inline: 20px;
  /* text-align: justify; */
  /* text-justify: inter-word; */
}

.a_link {
  color: black;
  text-decoration: none;
}
.a_link:hover {
  color: black;
  text-decoration: underline;
}
