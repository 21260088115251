.bg-team {
  background-color: #dce8f5;
}
.bg-team-card {
  padding: 3%;
  border-radius: 10px;
  box-shadow: 10px 10px 10px 10px grey;
}

.bg-team-h3 {
  color: #1f5156;
  /* box-shadow: 5px 5px 5px 5px grey; */
  /* font-family: cursive; */
  font-size: 50px;
  padding-bottom: 30px;
  font-weight: bold;
}
.p-team {
  font-size: 25px;
  font-weight: bold;
}

@media screen and (max-width: 960px) {
  .bg-team-h3 {
    color: #1f5156;
    /* box-shadow: 5px 5px 5px 5px grey; */
    /* font-family: cursive; */
    font-size: 35px;
    padding-bottom: 30px;
    font-weight: bold;
  }
}
