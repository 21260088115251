.item_name {
  padding: 10px;
  margin: 0px 5px 5px;
  width: 200px;
  justify-content: center;
  text-align: center;
}

.item_details {
  padding: 10px;
  margin: 0px 5px 5px;
  width: 100%;
}
.details_bg_1 p {
  background: #90ee90;
}
.details_bg_2 p {
  background: #ffe4e1;
}
