.btn-div {
  text-align: right;
}

.buttonStyle {
  background-color: #078823;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  text-align: right;
}

.slider-card-div {
  border-radius: 10px;
  overflow: hidden;
  /* box-shadow: 10px 10px #c6dbf1; */
}
.dash-slider-div-1 {
  position: relative;
  height: 400px;
  width: auto;
}
.dash-slider-div-1 img {
  height: 400px;
  width: 100%;
  /* object-fit: cover; */
}
.dash-slider-bottombar {
  position: absolute;
  top: 0px;
  right: 0px;
  color: white;
  background-color: transparent;
  border-radius: 10px 10px 0px 0px;
}
.delete-icon {
  visibility: hidden;
  font-size: 50px;
  background-color: transparent;
}
.delete-icon:hover {
  visibility: visible;
  font-size: 50px;
  background-color: transparent;
  color: red;
}

.slider-card-div:hover .delete-icon {
  visibility: visible;
}
.slider-card-div:hover {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 10px 10px #c6dbf1;
}
