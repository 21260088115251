.main-card:hover {
  border-radius: 10px;
  box-shadow: 5px 5px 20px gray;
}
.main-card:hover .div_footer {
  visibility: visible;
}
.main-card {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.div_footer {
  visibility: hidden;
}

.details_text {
  text-align: justify;
  text-justify: inter-word;
}

.read_more_div {
  position: relative;
  bottom: 10px;
  right: 10px;
}

.loader_div {
  height: 700px;
  width: 100%;
}

.loading-bg {
  background-color: #dce8f5;
}

.medi-title {
  color: #1f5156;
  /* box-shadow: 5px 5px 5px 5px grey; */
  /* font-family: cursive; */
  font-size: 40px;
  padding-bottom: 15px;
  font-weight: bold;
}
