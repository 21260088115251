.dash-auth-div {
  height: 100vh;
}

.dash-sign-div {
  width: 500px;
}

.auth-title {
  color: green;
  font-size: 50px;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.auth-div {
  padding: 50px;
  box-shadow: 5px 10px 5px 10px #888888;
  border-radius: 10px;
  border: 2px solid;
}

.input-label {
  color: black;
  font-size: 20px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}
