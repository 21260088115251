.dash-program-bottombar {
  position: absolute;
  bottom: 0px;
  color: black;
  /* width: 500px; */
  /* height: 200px; */
  /* background-color: red; */
  border-radius: 10px 10px 0px 0px;
}

.dash-program-delete {
  position: absolute;
  top: 0px;
  right: 0px;
  color: white;
  background-color: transparent;
  border-radius: 10px 10px 0px 0px;
}



